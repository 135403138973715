import { useState, useEffect } from 'react';

export function useRotatingItem(initialArray) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % initialArray.length);
        }, 4000);

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [initialArray.length]); // Dependency on the length of the array

    return initialArray[index];
}