import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import rctIcon from "./images/rctIcon.png";
import techstartlogo from "./images/techstartlogo.png";
import dealHawkLogo from "./images/dealHawk.png";
import { useState } from "react";

const sideProjects = [
  {
    title: "Deal Hawk",
    previewImg: dealHawkLogo,
    contentParagraphs: [
      <p>
        I built Deal Hawk, a shopping comparison engine that saves you money and
        time when shopping online.
      </p>,
      <p>
        With one click, you can scan the web to find the best deals for similar
        products. Building it involved creating a scalable web crawler that
        could bypass Amazon's anti-scraping measures to produce a dataset of the
        top 6 Million Amazon products. With that dataset, I created a web-scale
        semantic search & reverse-image search engine.
      </p>,
      <p>
        Deal Hawk's backend is relatively expensive to host so I've taken it
        down temporarily, but if you would like a demo just let me know.
      </p>,
    ],
  },
  {
    title: "Reimagine Calgary Transit",
    previewImg: rctIcon,
    contentParagraphs: [
      <iframe
        src="https://www.youtube.com/embed/tvMkyNZfEBY"
        frameBorder={0}
        allow="encrypted-media"
        allowFullScreen
        title="Reimagine Calgary Transit video"
        width={"80%"}
        height="80%"
      />,
      <p>
        I led a political initiative to rethink how we run transit in the City
        of Calgary. I wrote a report and made a map for a proposed bus network
        overhaul, recently published a video as part of public advocacy, met
        with city councillors to advocate for a 20% boost in funds.
      </p>,
    ],
  },
  {
    title: "Tech Start UCalgary",
    previewImg: techstartlogo,
    contentParagraphs: [
      <p>
        While at the U of C, I founded Tech Start UCalgary, a club dedicated to
        teaching members software & business skills through entrepreneurial
        group projects. I assembled a strong team and served as President from
        2020-2022, then as Chairman from 2022 onwards. I also served as PM for a
        team of students to build our website{" "}
        <a
          href="https://techstartucalgary.com"
          target="_blank"
          rel="noreferrer"
        >
          techstartucalgary.com
        </a>
        .
      </p>,
      <p>
        Since our founding, we've helped more than 150 members build their
        skills and prep for careers in tech.
      </p>,
    ],
  },
];

const defaultProject = {
  title: "Side Projects",
  contentParagraphs: [
    <p>
      Here's a selection of some of the awesome things I've built in my spare
      time.
    </p>,
    <p>Just click on any of the buttons to see more details.</p>,
  ],
};

// interface SelectProjectButtonProps {
//     previewImg?: any;
//     title: string;
//     setActiveProjectTitle: (x: string) => void;
// }

function SelectProjectButton(props) {
  const { previewImg, title, setActiveProjectTitle, activeTitle } = props;
  const isActive = activeTitle === title;
  const toSet = isActive ? undefined : title;
  return (
    <Button
      height={["80px", "120px", "160px"]}
      width={["80px", "120px", "160px"]}
      borderRadius={["16px"]}
      onClick={(e) => {
        e.preventDefault();
        setActiveProjectTitle(toSet);
      }}
      borderTop="1px solid rgba(255, 255, 255, 0.5);"
      borderLeft="1px solid rgba(255, 255, 255, 0.5);"
      background="rgba(255, 255, 255, 0.1);"
      backdropFilter="blur(5px);"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;"
      cursor="pointer"
      padding="0"
    >
      <Image
        src={previewImg}
        alt={title}
        height={"80%"}
        width="80%"
        borderRadius={"12px"}
      />
    </Button>
  );
}

export function SideProjects() {
  const [activeProjectTitle, setActiveProjectTitle] = useState();
  const activeProject =
    sideProjects.find((project) => project.title === activeProjectTitle) ??
    defaultProject;

  return (
    <Flex
      margin={["0 5% 50px", "0 5% 50px", "0 15% 50px"]}
      direction={["column", "row"]}
    >
      <Box
        className="glass"
        maxWidth={"700px"}
        width={["100%", "auto"]}
        margin={["25px auto 0", "initial"]}
      >
        <h2 className="subtitle pixelated">{activeProject.title}</h2>
        {activeProject.contentParagraphs}
      </Box>
      <Center margin={["16px 0 0 0", "0 0 0 16px"]}>
        <Stack alignContent={"center"} direction={["row", "column"]}>
          {sideProjects.map((p) => (
            <SelectProjectButton
              previewImg={p.previewImg}
              title={p.title}
              key={p.title}
              setActiveProjectTitle={setActiveProjectTitle}
              activeTitle={activeProjectTitle}
            />
          ))}
        </Stack>
      </Center>
    </Flex>
  );
}
