import joelAsBorat from "./images/borat.png";
import joelInCave from "./images/cave.JPG";
import joelInCanmore from "./images/inCanmore.jpg";
import "./App.css";
import Particles from "react-tsparticles";
import stars1 from "./starparticles1.json";
import stars2 from "./starparticles2.json";
import stars3 from "./starparticles3.json";
import {
  FaFilePdf,
  FaGithub,
  FaGraduationCap,
  FaLinkedinIn,
  FaMailBulk,
  FaTwitter,
} from "react-icons/fa";
import SlidingBar from "./SlidingBar";
import { useRotatingItem } from "./useRotatingItem";
import { SideProjects } from "./SideProjects";
import { Center, ChakraProvider, Flex, Stack, Text } from "@chakra-ui/react";

function App() {
  const mainImgSrc = useRotatingItem([joelInCanmore, joelInCave, joelAsBorat]);
  var year = new Date().getFullYear();

  return (
    <ChakraProvider cssVarsRoot="body">
      <div className="App">
        <header className="header">
          <div className="starContainer">
            <Particles
              id="starry1"
              className="stars stars--1"
              options={stars2}
            />
            <Particles
              id="starry3"
              className="stars stars--3"
              options={stars3}
            />
            <Particles
              id="starry2"
              className="stars stars--1"
              options={stars1}
            />
          </div>
          <div className="scrollable">
            <Center>
              <div className="nfpolandball">
                <img
                  src={mainImgSrc}
                  className="App-logo"
                  alt="Joel Happ"
                  style={{
                    borderRadius: "50%",
                    boxShadow: "3px 10px 10px 10px rgba(0,0,0,0.1)",
                  }}
                />
              </div>
            </Center>
            <h1
              className="pixelated splashText"
              style={{
                letterSpacing: ".2em",
                textTransform: "uppercase",
                fontSize: "80px",
              }}
            >
              Joel Happ
            </h1>
            <div className="newsBox">
              <p className="news">I build cool things.</p>
            </div>
            <div className="glass mainContent">
              <h2 className="subtitle pixelated">About Me</h2>
              <p>
                I'm a leader and software engineer with a passion for taking on
                huge challenges. I am creative, driven, and entrepreneurial, and
                my dream is to change the world for the better.{" "}
              </p>
              <p>
                I'm currently working at Microsoft, applying AI to solve big
                engineering challenges.{" "}
                <a
                  href="https://www.youtube.com/watch?v=N9qYF9DZPdw&pp=ygUPd2hpdGUgYW5kIG5lcmR5"
                  target="_blank"
                  rel="noreferrer"
                >
                  I'm fluent in Javascript
                </a>
                , but my Klingon is still a work in progress.
              </p>
            </div>

            <div className="ballCont"></div>

            <div className="glass mainContent">
              <h2 className="subtitle pixelated">Storiator</h2>
              <p>
                Outside of work, I'm building{" "}
                <a
                  href="https://storiator.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storiator
                </a>{" "}
                - the future of AI-powered entertainment.
              </p>
              <p>
                With the power of large language models, Storiator lets you play
                roleplaying games with your friends, or chat with your favorite
                AI-powered characters. I believe that AI will enable a totally
                new genre of media - dynamically generated games and stories
                with limitless possibilities. We're releasing a new AI murder
                mystery game soon!
              </p>
            </div>
          </div>
          <div className="documents">
            <div style={{ height: "20px", content: "" }}></div>
            <SlidingBar
              name="Resume"
              icon={<FaFilePdf className="slidingbar__fab" />}
              link="https://drive.google.com/file/d/1SFzl89rdRhf-ZgVQQlAv-H8cGcBZg7wy/view?usp=sharing"
            ></SlidingBar>
            <div className="documents__barspace"></div>
            <SlidingBar
              name="Transcript"
              isRight={true}
              icon={<FaGraduationCap className="slidingbar__fab" />}
              link="https://drive.google.com/file/d/1yQIx9Rk3KIZgLcboIJVmsffAnPavxahd/view?usp=sharing"
            ></SlidingBar>
            <div className="documents__barspace"></div>
            <SlidingBar
              name="Github"
              icon={<FaGithub className="slidingbar__fab" />}
              link="https://github.com/Jhappy77"
            ></SlidingBar>
            <div style={{ height: "40px", content: "" }}></div>
          </div>

          <SideProjects />
        </header>

        <Flex
          direction={["column-reverse", "column-reverse", "row"]}
          zIndex={40}
          backgroundColor={"#090911"}
          position={"sticky"}
          minHeight="100px"
          padding={["30px 0 40px", "30px 0 40px", "10px 0"]}
        >
          <Text
            alignSelf={"center"}
            className="copyright"
            marginLeft={["0", "0", "10%"]}
            textAlign={"center"}
          >
            © {year} Joel Happ
          </Text>
          <Stack
            direction={"row"}
            justifySelf={"center"}
            minHeight={"80px"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            flexGrow={"1"}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:joelhapp7@gmail.com"
            >
              <FaMailBulk className="socialIcon" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linkedin.com/in/joel-happ"
            >
              <FaLinkedinIn className="socialIcon" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/joelhapp"
            >
              <FaTwitter className="socialIcon" />
            </a>
          </Stack>
        </Flex>
      </div>
    </ChakraProvider>
  );
}

export default App;
